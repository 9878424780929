import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import theme from "../../src/theme";
import TopAppBar from "../../src/components/v2/TopAppBar";
import Footer from "../../src/components/v2/Footer";
import favicon from "../../src/images/icon.png";
import Box from "@material-ui/core/Box";
import { ContactUsDialogContextProvider } from "../../src/components/v2/ContactUsDialog";

const useStyles = makeStyles((theme) => ({
  appRootBody: {
    maxWidth: "100%",
    clear: "both",
    display: "inline-block",
  },
}));
export default function TopLayout({ children, pathname }) {
  const classes = useStyles();
  const isSingaporeSME =
    pathname === "/crm-software-for-small-business-singapore" ||
    pathname === "/best-sales-crm-software-guatemala-for-small-business" ||
    pathname === "/singapore-sme-v1.1" ||
    pathname === "/singapore-sme-v2" ||
    pathname === "/singapore-whatsapp-crm" ||
    pathname === "/whatsapp-crm-system-dubai-uae" ||
    pathname === "/crm-with-whatsapp-and-aircall-integration" ||
    pathname === "/crm-with-whatsapp-integration" ||
    pathname === "/whatsapp-ad-landing-page" ||
    pathname === "/crm-partners/tricove-tech";
  const isRedirectPage = pathname === "/redirect";
  const [loadFooter, setLoadFooter] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoadFooter(true), 400);
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        if (
          location.hostname === "localhost" ||
          location.hostname === "127.0.0.1"
        ) {
          navigator.serviceWorker
            .getRegistrations()
            .then(function (registrations) {
              for (let registration of registrations) {
                registration.unregister();
              }
            });
        } else {
          navigator.serviceWorker
            .register("service-worker.js")
            .then((registration) => {
              // eslint-disable-next-line no-console
              console.log("Service Worker is registered", registration);
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.error("Registration failed:", err);
            });
        }
      });
    }
  }, [isSingaporeSME]);

  return (
    <React.Fragment>
      <Helmet>
        <meta
          content="minimum-scale=1, initial-scale=1, width=device-width"
          name="viewport"
        />
        {/*<link*/}
        {/*  href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"*/}
        {/*  rel="stylesheet"*/}
        {/*/>*/}
        {/* <link
					as="style"
					href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap"
					onLoad="this.onload=null;this.rel='stylesheet'"
					rel="preload"
				/>
				<noscript>
					<link
						href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap"
						rel="stylesheet"
						type="text/css"
					/>
				</noscript>
				<link
					as="style"
					href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
					onLoad="this.onload=null;this.rel='stylesheet'"
					rel="preload"
				/>
				<noscript>
					<link
						href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
						rel="stylesheet"
						type="text/css"
					/>
				</noscript> */}

        {/* <link href="https://fonts.gstatic.com" rel="preconnect" /> */}
        {/* <link
					href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap"
					rel="stylesheet"
				/>
				<link
					href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
					rel="stylesheet"
				/> */}
        {/*<link href={styles} rel="stylesheet" />*/}
        <link href={favicon} rel="icon" />
        <meta content="index,follow" name="robots" />
        <meta content="index,follow" name="googlebot" />
        <meta content={theme.palette.primary.main} name="theme-color" />
        <link
          href="/icons/apple-icon-57x57.png"
          rel="apple-touch-icon"
          sizes="57x57"
        />
        <link
          href="/icons/apple-icon-60x60.png"
          rel="apple-touch-icon"
          sizes="60x60"
        />
        <link
          href="/icons/apple-icon-72x72.png"
          rel="apple-touch-icon"
          sizes="72x72"
        />
        <link
          href="/icons/apple-icon-76x76.png"
          rel="apple-touch-icon"
          sizes="76x76"
        />
        <link
          href="/icons/apple-icon-114x114.png"
          rel="apple-touch-icon"
          sizes="114x114"
        />
        <link
          href="/icons/apple-icon-120x120.png"
          rel="apple-touch-icon"
          sizes="120x120"
        />
        <link
          href="/icons/apple-icon-144x144.png"
          rel="apple-touch-icon"
          sizes="144x144"
        />
        <link
          href="/icons/apple-icon-152x152.png"
          rel="apple-touch-icon"
          sizes="152x152"
        />
        <link
          href="/icons/apple-icon-180x180.png"
          rel="apple-touch-icon"
          sizes="180x180"
        />
        <link
          href="/icons/android-icon-192x192.png"
          rel="icon"
          sizes="192x192"
          type="image/png"
        />
        <link
          href="/icons/icon-image-32x32.png"
          rel="icon"
          sizes="32x32"
          type="image/png"
        />
        {/*<link href="/icons/favicon-96x96.png" rel="icon" sizes="96x96" type="image/png" />*/}
        <link
          href="/icons/icon-image-16x16.png"
          rel="icon"
          sizes="16x16"
          type="image/png"
        />
        <link href="/icons/manifest.json" rel="manifest" />
        <meta content="#ffffff" name="msapplication-TileColor" />
        <meta
          content="/icons/ms-icon-144x144.png"
          name="msapplication-TileImage"
        />
        <meta
          content="Yw8hW4SPrn_OsvF1k5V7zEZVcf4h5MP3-dhRPk96hJk"
          name="google-site-verification"
        />
      </Helmet>
      <ContactUsDialogContextProvider>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          {!(isRedirectPage || isSingaporeSME) && (
            <TopAppBar pathname={pathname} />
          )}
          <Box maxWidth="100%" overflow="visible">
            {children}
          </Box>
          {!(isRedirectPage || isSingaporeSME) && loadFooter && (
            <Footer showWidget={true} />
          )}
        </ThemeProvider>
      </ContactUsDialogContextProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node.isRequired,
  pathname: PropTypes.string.isRequired,
};
